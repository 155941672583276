<template lang="pug">
  .full-width.dyo-view
    .dyo-view-header.is-hidden-touch.container.is-flex
      .stone.is-flex
        icon(iconName="menuStone", :iconColor="$site.primaryColor", width="24", height="24")
        span {{ $t('layout.mainMenu.startWithStone') }}
      .or {{ $t('layout.mainMenu.or') }}
      .setting.is-flex
        icon(iconName="menuRing", :iconColor="$site.defaultColor", width="24", height="24")
        span {{ $t('layout.mainMenu.setting') }}
    .dyo-view-body.lazy(data-lazy-function="loaded")
      .dyo-view-body-columns.container.is-flex
        .body-column.left-column
          .dyo-view-header.is-hidden-desktop
            .stone.is-flex
              icon(iconName="menuStone", iconColor="#2e2e2e", width="24", height="24")
              span {{ $t('layout.mainMenu.startWithStone') }}
          .sub-menu(v-for="(subMenu, key) in leftMenus", :key="key")
            .sub-menu__title {{ $t(`layout.mainMenu.${key}`) }}
            .sub-menu__items-wrapper.is-flex
              .sub-menu__item(v-for="menuItem in leftMenus[key]", :key="menuItem.id", :class="{ more: !menuItem.img }")
                v-link(:to="menuItem.to", v-if="menuItem.img")
                  v-image.sub-menu__item--img(:src="menuItem.img", :alt="menuItem.text")
                  .sub-menu__item--name {{ menuItem.text }}
                v-link.sub-menu__item--more.is-flex(:to="menuItem.to", v-else)
                  .sub-menu__item--name(v-html="menuItem.text")
        .body-column.right-column
          .dyo-view-header.is-hidden-desktop
            .setting.is-flex
              icon(iconName="menuRing", iconColor="#2e2e2e", width="24", height="24")
              span {{ $t('layout.mainMenu.setting') }}
          .sub-menu
            .sub-menu__title {{ $t('layout.mainMenu.settingsByType') }}
            .sub-menu__items-wrapper.is-flex
              .sub-menu__item(v-for="menuItem in rightMenus.settingsByType", :key="menuItem.id")
                v-link(:to="menuItem.to")
                  v-image.sub-menu__item--img.is-flex(:src="menuItem.img", :alt="menuItem.text")
                  .sub-menu__item--name {{ menuItem.text }}
          .sub-menu.sub-menu__style
            .sub-menu__title {{ $t('layout.mainMenu.settingsByStyle') }}
            .sub-menu__items-wrapper.is-flex
              .sub-menu__item(v-for="menuItem in rightMenus.settingsByStyle", :key="menuItem.id")
                v-link.sub-menu__item--icon.is-flex(:to="menuItem.to", v-if="menuItem.icon")
                  icon(:iconName="menuItem.icon", iconColor="#2e2e2e")
                  .sub-menu__item--name {{ menuItem.text }}
</template>

<script>
export default {
  name: 'DyoComponent',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    leftMenus() {
      return this.item.leftItems
    },

    rightMenus() {
      return this.item.rightItems
    },

    routeToPresentation() {
      return {
        name: 'custom-design-gemstone-rings-jewelry'
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.full-width
  border-top: 1px solid $border-color
  width: 100%
.dyo-view
  padding: 0
  @include box-shadow(0 4px 5px -3px, rgba(0,0,0,0.2))
  +touch
    border-top: unset
    box-shadow: unset
  &-body-columns
    +touch
      flex-direction: column
  &-header
    height: 60px
    align-items: center
    text-align: center
    font-size: 18px
    line-height: 24px
    font-weight: 600
    background-color: $secondary-bg-color
    +touch
      height: unset
      padding-bottom: 12px
    &::before
      content: ''
      position: absolute
      top: 0
      left: 50%
      transform: translateX(-50%)
      width: 100vw
      height: 60px
      z-index: -1
      background-color: $secondary-bg-color
      +touch
        height: unset
    > *
      flex: 3
      justify-content: center
      align-items: center
    .or
      flex: 1
      text-transform: uppercase
      font-size: 12px
      line-height: 15px
    .setting,
    .stone
      span
        margin-left: 15px
    .setting
      svg
        transform: rotate(-45deg)
  &-body
    position: relative
    z-index: 1
    font-size: 16px
    background-color: #fff
    padding: 35px
    +touch
      padding: 0
    .body-column
      width: 50%
      +touch
        width: 100%
    .body-column + .body-column
      border-left: 1px solid $border-color
      +touch
        border-left: unset
        border-top: 12px solid $light-primary-color
    .left-column
      .sub-menu
        &__item
          +touch
            &:nth-of-type(1n+4)
              display: none
            &.more
              display: block
      .sub-menu + .sub-menu
        margin-top: 75px
        +touch
          margin-top: -20px
    .right-column
      .sub-menu + .sub-menu
        margin-top: 55px
        +touch
          margin-top: -20px
    .sub-menu
      width: 100%
      +touch
        padding: 20px
      &__title
        font-size: 16px
        font-family: $lora
        font-style: italic
      &__items-wrapper
        margin-top: 24px
        justify-content: space-between
        font-size: 14px
        flex-wrap: wrap
        +touch
          margin-top: 20px
      &__item--img
        background-color: $secondary-bg-color
        border: 5px solid $secondary-bg-color
        aspect-ratio: 1 / 1
        align-items: center
      &__item--name
        margin-top: 10px
      &__item--more
        justify-content: center
        align-items: center
        border: 1px solid $secondary-bg-color
        max-height: 80px
        height: 100%
        font-weight: bold
      &__item
        text-align: center
        max-width: 80px
        &.more
          padding-bottom: 24px
          width: 100%
          aspect-ratio: 1 / 1
          .sub-menu__item--name
            margin-top: unset
    .left-column
      padding-right: 35px
      +touch
        padding-right: 0
    .right-column
      padding-left: 140px
      +touch
        padding-left: 0
      .sub-menu__style
        .sub-menu__items-wrapper
          flex-wrap: wrap
          margin-top: 12px
          justify-content: unset
          column-gap: 12px
        .sub-menu__item
          max-width: unset
          border: 1px solid $border-color
          padding: 10px 12px 9px
          margin-top: 12px
          .sub-menu__item--name
            margin-left: 10px
            margin-top: unset
        .sub-menu__item--icon
          align-items: center
</style>
